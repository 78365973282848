import { NextSeo } from 'next-seo';

import { ERRORPAGE } from 'src/constants/error';
import ErrorPage from 'src/containers/ErrorPage';

import { noIndexNoFollow } from './_app';

function Error({ statusCode }: { statusCode: number | string }) {
	const errorData = ERRORPAGE[statusCode] ?? ERRORPAGE.default;
	let title = errorData?.title;

	return (
		<>
			<NextSeo {...noIndexNoFollow} title={title} />
			<ErrorPage status={statusCode} />
		</>
	);
}

Error.getInitialProps = ({ res, err }: { res: any; err: any }) => {
	const errStatusCode = err ? err.statusCode : 500;
	const statusCode = res ? res.statusCode : errStatusCode;
	return { statusCode };
};

export default Error;
