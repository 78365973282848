import Link from 'next/link';
import Col from 'antd/lib/col';
import Row from 'antd/lib/row';
import classNames from 'classnames';

import { ButtonPrimary } from 'src/components/Button';
import ImageC from 'src/components/Image';
import { ERRORPAGE } from 'src/constants/error';

import classes from './index.module.less';

interface Props {
	status?: any;
	title?: string;
	description?: string;
}

const ErrorPage = ({ status, ...props }: Props) => {
	const errorData = ERRORPAGE[status] ?? ERRORPAGE.default;
	const title = props.title ?? errorData?.title;
	const description = props.description ?? errorData?.description;

	return (
		<div className={classNames('container', classes['error-page-container'])}>
			<Row align="middle">
				<Col xs={24} md={12}>
					{status ? <div className={classes.status}>{status} Error</div> : <div>Oops!</div>}
					{title?.length > 0 && <div className={classes.title}>{title}</div>}
					{description?.length > 0 && <div className={classes.description}>{description}</div>}
					<Link href="/">
						<ButtonPrimary className={classes['home-btn']}>Back to Home</ButtonPrimary>
					</Link>
				</Col>
				<Col xs={24} md={12}>
					<ImageC className={classes.image} src="/images/error.svg" />
				</Col>
			</Row>
		</div>
	);
};

export default ErrorPage;
