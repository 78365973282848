export const ERRORPAGE: any = {
	404: {
		imageUrl: '/images/error.svg',
		title: 'Page Not Found',
		description: 'We can’t find the page you are looking for.',
	},
	500: {
		imageUrl: '/images/error.svg',
		title: 'Something went wrong',
		description: 'Please try again later',
	},
	default: {
		imageUrl: '/images/error.svg',
		title: 'Something went wrong',
		description: 'Please try again later',
	},
};
